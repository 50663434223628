<template>
  <div class="user-privileges-management">
    <v-user-privileges-editor-card />
  </div>
</template>

<script>
import VUserPrivilegesEditorCard from "./cards/VUserPrivilegesEditorCard";

export default {
  name: "VUserPrivilegesManagement",

  components: { VUserPrivilegesEditorCard },
};
</script>

<style lang="scss" scoped>
</style>