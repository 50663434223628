<template>
  <div class="user-privileges"><v-user-privileges-management /></div>
</template>

<script>
import VUserPrivilegesManagement from "../../components/users/VUserPrivilegesManagement";

export default {
  name: "UserPrivileges",

  components: { VUserPrivilegesManagement },
};
</script>

<style lang="scss" scoped>
</style>