



















































import { Component, Vue } from "vue-property-decorator";
import usersService, {
  PermissionsGroups,
  PermissionResponse,
} from "../../../services/users.service";
import { capitalize } from "../../../services/filters.service";

import { toast } from "../../../helpers/dialogs";
import { UserModel } from "@/@types/model";

@Component({ name: "VUserPrivilegesEditorCard" })
export default class VUserPrivilegesEditorCard extends Vue {
  user!: UserModel;

  permissionsGroups!: PermissionResponse;
  permissionsCodes: PermissionsGroups[] = [];

  isUserLoading: boolean = false;
  isBusy: boolean = false;

  data() {
    return {
      user: null,
      permissionsGroups: null,
    };
  }

  capitalize(value: string) {
    return capitalize(value);
  }

  async created() {
    const userId = this.$route.params.userId;
    if (!userId) return;

    try {
      this.isUserLoading = true;
      const user = await usersService.getById(userId);
      if (!user) {
        throw new Error(`${this.$t("InternalError.UserNotFoundWithId", { id: userId })}`);
      }

      this.user = {
        ...user,
        permissions: [...user.permissions, ...user.role!.permissions],
      };

      const permissionsGroups = await usersService.getAllPermissions();
      if (!permissionsGroups) {
        throw new Error(`${this.$t("InternalError.PermissionsGroupsNotFound")}`);
      }

      this.permissionsGroups = { supplier: permissionsGroups.supplier } as PermissionResponse;
      this.permissionsCodes = Object.keys(this.permissionsGroups) as PermissionsGroups[];
    } catch (e) {
      console.error(e);
    } finally {
      this.isUserLoading = false;
    }
  }

  inUserRolePermissions(id: string | number) {
    if (!this.user) return false;
    return Boolean(this.user.role?.permissions.find((permission) => permission.id === id));
  }

  async save() {
    if (!this.user || !this.user.id) return;

    this.isBusy = true;

    const permissions = this.user.permissions.filter(
      (permission) => !this.inUserRolePermissions(permission.id),
    );

    try {
      await usersService.updateById(this.user.id, { permissions });
      this.user.permissions = permissions;

      const notifySettings = toast("Changes Saved Successfully");
      this.$dialog.notify.success(...notifySettings);
      this.$router.push({ name: "users" });
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
