import axios from "axios";

import catchErrors from "../helpers/catchErrors";
import { API_URL, PERMISSIONS_GROUPS } from "../config";

import { PermissionModel, RoleModel, UserModel } from "@/@types/model";

export type PermissionsGroups = typeof PERMISSIONS_GROUPS[number]
export type PermissionResponse = {
  [key in PermissionsGroups]: {[key: string]: PermissionModel[]};
}

const getAllRoles = catchErrors<RoleModel[]>(async () => {
  const { data, status } = await axios.get<RoleModel[]>(`${API_URL}/roles`);

  const roles = data.map((role) => ({ ...role, permissions: role.permissions || [] }));
  return status === 200 && roles ? roles : [];
});

const getAllPermissions = async () => {
  const { data, status } = await axios.get<PermissionResponse>(`${API_URL}/permissions`);
  return status === 200 && data ? data : null;
};

const getAll = catchErrors<UserModel[]>(async () => {
  const { data, status } = await axios.get(`${API_URL}/users`);
  return status === 200 && data ? data : [];
});

const getById = async (userId: string | number) => {
  const { data, status } = await axios.get<UserModel>(`${API_URL}/users/${userId}`);
  return status === 200 && data ? data : null;
};

const create = async (user: Omit<UserModel, "id">) => {
  const { data, status } = await axios.post(`${API_URL}/users`, user);
  return status === 200 && data ? data : null;
};

const updateById = async (userId: string | number, user: Partial<UserModel>) => {
  const { status } = await axios.patch(`${API_URL}/users/${userId}`, user);
  return status === 200;
};

const deleteById = catchErrors<boolean>(async (userId: string | number) => {
  const { status } = await axios.delete(`${API_URL}/users/${userId}`);
  return status === 201;
});

export default { getAllRoles, getAllPermissions, getAll, getById, create, updateById, deleteById };
